<template>
    <div class="container">
        <div class="card">
            <div class="card-content">
                <section class="section">
                    <div class="container">
                        <b-field label="Username" message="Please log-in with your NYU Net ID and Password">
                            <b-input @keyup.native.enter="login({ Username, Password })" v-model="Username"></b-input>
                        </b-field>

                        <b-field label="Password">
                            <b-input @keyup.native.enter="login({ Username, Password })" type="password"
                                v-model="Password" password-reveal>
                            </b-input>
                        </b-field>

                        <b-button @click="login({ Username, Password })" type="is-primary">Login</b-button>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, } from 'vuex';

export default {
    name: 'LoginView',
    data() {
        return {
            Username: null,
            Password: null
        }
    },
    methods: {
        ...mapActions(['login'])
    }
};
</script>