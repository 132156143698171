<template>
    <div id="app">
        <nav class="navbar is-primary is-fixed-top" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <a class="navbar-item">
                    <img class="height-unrestricted" src="https://www.law.nyu.edu/themes/custom/nyulaw/logo.svg"
                        alt="NYU Law" width="240" height="45">
                </a>

                <div class="navbar-item">
                    <div style="display:block;">
                        <h1 class="title is-1 has-text-white">CAMS</h1>
                        <h2 class="subtitle is-6 has-text-white">Clinic Application and Matching System Rankings</h2>
                    </div>
                </div>
            </div>
        </nav>

        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
        <router-view></router-view>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'App',

    methods: {
        ...mapActions(['goHome', 'goToClinics', 'logout'])
    },

    computed: {
        ...mapGetters(['isLoggedIn'])
    }
}
</script>

