<template>
    <div>
        <div class="columns" style="background-color: #1E0635;">
            <div class="column">
                <strong class="title is-5"><a class="has-text-white" @click="goHome">Home</a></strong>
            </div>

            <div class="column">
                <strong class="title is-5"><a class="has-text-white" @click="goToClinics">Clinics</a></strong>
            </div>

            <div class="column" v-if="isLoggedIn">
                <strong @click="logout" class="title is-5"><a class="has-text-white">Log Out</a></strong>
            </div>
        </div>
        <div class="container">
            <div class="card">
                <div class="card-content">
                    <aside class="menu">
                        <p class="menu-label">
                            <strong class="title">Clinics</strong>
                        </p>
                        <p>
                            Below are the clinics you have access to. Click on the name of a clinic to navigate to its
                            respective ranking page.
                        </p>
                        <br />
                        <ul class="menu-list">
                            <li v-for="c in clinics" :key="c.clinicId"><a class="has-text-link"
                                    @click="setClinic(c.clinicId)">{{ c.clinicName }}</a>
                            </li>
                        </ul>
                    </aside>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ClinicsView",
    methods: {
        ...mapActions(['goHome', 'goToClinics', 'logout', 'setClinic'])
    },
    computed: {
        ...mapGetters(["clinic", "clinics", "isLoggedIn"]),
    },
}
</script>
