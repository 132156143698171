import Vue from 'vue'
import Vuex from 'vuex';
import Router from 'vue-router';
import Buefy from 'buefy'
import App from './App.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'buefy/dist/buefy.css';
import './fontAwesome';
import './assets/styles.css';
require('./assets/site.scss');
import LoginView from './components/Login';
import HomeView from './components/Home';
import ClinicsView from './components/Clinics';
import PageNotFound from './components/404';
import { ToastProgrammatic as Toast } from 'buefy'

// httpss://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
// This code will suppress the uncaught exception thrown when calling either
// with $router.push or $router.replace methods.
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);

  return originalPush.call(this, location).catch(err => err);
};

const originalReplace = Router.prototype.replace;

Router.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);

  return originalReplace.call(this, location).catch(err => err);
};

Vue.config.productionTip = false

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(Router);

let routes = [
  {
    path: '/',
    name: 'root',
    meta: {
      isPublic: true
    },
    component: LoginView
  },
  {
    path: '/signin'
  },
  {
    path: '/signin/Login'
  },
  {
    path: '*',
    component: PageNotFound,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/home',
    component: HomeView,
    meta: {
      isPublic: false
    }
  },
  {
    path: '/clinics',
    component: ClinicsView,
    meta: {
      isPublic: false
    }
  }
];

const userBasedNavigationGuards = (to, next) => {
  let isPublic = false;
  let hasPublicSetting = to.matched.some(record => 'isPublic' in record.meta);

  if (!hasPublicSetting) {
    next();
    return;
  }

  if (hasPublicSetting) {
    isPublic = to.matched.filter(path => 'isPublic' in path.meta)[0].meta
      .isPublic;
  }

  if (!hasPublicSetting) isPublic = false;

  if (isPublic) {
    next();
    return;
  }

  if (store != null) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
  }

  next({ path: '/NotFound', replace: true });
  return;
};

const router = new Router({
  routes,

  mode: 'history',

  base: process.env.BASE_URL,

  linkActiveClass: 'is-active',

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { selector: to.hash };
    }

    if (to.meta.scrollToTop) {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => userBasedNavigationGuards(to, next));

// Create a new store instance.
const store = new Vuex.Store({
  state() {
    return {
      loggedIn: false,
      clinic: null,
      clinicId: null,
      rankingData: [],
      clinics: [],
      username: null
    }
  },
  mutations: {
    logIn(state, payload) {
      state.loggedIn = true;
      state.clinics = payload.clinics;
      state.rankingData = payload.rankingData;
      if (state.clinics.length >= 1) {
        state.clinic = state.clinics[0].clinicName;
        state.clinicId = state.clinics[0].clinicId;
      }
      state.username = payload.username;
      /** Array.isArray(payload.rankingData) && payload.rankingData.length == 0 */
      // eslint-disable-next-line no-constant-condition
    },
    logout(state) {
      state.loggedIn = false;
      state.clinic = null;
      state.clinicId = null;
      state.clinics = [];
      state.rankingData = [];
      state.username = null;
    },
    setRankingData(state, rankingData) {
      state.rankingData = rankingData;
    },
    setClinic(state, clinicId) {
      let clinicIfFound = null;

      if (Array.isArray(state.clinics) && state.clinics.length > 0) {
        clinicIfFound = state.clinics.find(c => c.clinicId == clinicId);

        if (clinicIfFound != null) {
          state.clinic = clinicIfFound.clinicName;
          state.clinicId = clinicIfFound.clinicId;
        }
      }
    },
    saveSubmission(state, applications) {
      applications.forEach(app => {
        const match = state.rankingData.find(a => a.applicationId == app.applicationId);
        match.rank = app.rank;
      });
    },
    saveLockState(state, application) {
      state.rankingData = state.rankingData.map(record => record.applicationId == application.applicationId ? application : record);
    }
  },
  actions: {
    async login({ commit }, loginForm) {
      let response;

      let validLogin = false;
      let errorMessage;

      try {
        response = await fetch(`https://${window.location.host}/api/signin`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ Username: loginForm.Username, Password: loginForm.Password })
        });
        validLogin = response.ok;
      }
      catch (ex) {
        errorMessage = ex;
        validLogin = false;
      }

      if (validLogin) {
        const payload = {};
        try {
          const json = await response.json();

          payload.clinics = json.clinics;
          payload.rankingData = json.rankings;
          payload.username = json.username;
        }
        catch (ex) {
          validLogin = false;
        }

        commit('logIn', payload);

        if (payload.clinics.length > 1) {
          router.push('/clinics');
        }
        else {
          router.push('/home');
        }
      }
      else {
        // router.push('/home');
        Toast.open(errorMessage == null ? 'Not a valid login' : errorMessage);
      }
    },
    logout({ commit }) {
      commit('logout');
      router.push('/');
    },
    goHome() {
      router.push('/home');
    },
    goToClinics() {
      router.push('/clinics');
    },
    async postRankings({ commit }, rankingData) {
      let response;

      const applications = rankingData;

      try {
        response = await fetch(`https://${window.location.host}/api/ranking`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(applications)
        });
      }
      catch (ex) {
        return;
      }

      const appResponse = await response.json();

      commit('saveSubmission', appResponse);

      // commit('setRankingData', json.rankingData);
      Toast.open('Rankings successfully saved!');
    },
    setClinic({ commit }, clinicId) {
      commit('setClinic', clinicId);
      router.push('/home');
    },
    async toggleLock({ commit, getters }, record) {
      if (!getters.isAdmin) return;

      let response;

      const payload = record;

      try {
        response = await fetch(`https://${window.location.host}/api/lock`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload)
        });
      }
      catch (ex) {
        Toast.open(`There was an issue toggling the lock`);
        return;
      }

      const toggleLockResponse = await response.json();

      commit('saveLockState', toggleLockResponse);

      Toast.open(`Application successfully ${toggleLockResponse.isLocked == "Y" ? "locked" : "unlocked"}`);
    }
  },
  getters: {
    isLoggedIn: state => state.loggedIn,
    clinic: state => state.clinic,
    clinicRanks: state => {
      return state.rankingData.filter(d => {
        return d.clinicId == state.clinicId
      });
    },
    clinics: state => state.clinics,
    isAdmin: state => {
      // @TODO: replace this with a server side proper check
      return ['er1572', 'jl531', 'seh1', 'rh2', 'ls157', 'dn5', 'sab9848', 'ymb2'].some(u => u == state.username);
    }
  }
});

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultIconComponent: 'font-awesome-icon'
});

new Vue({
  store: store,
  router: router,
  render: h => h(App),
}).$mount('#app')

