<template>
    <section class="section">
        <div class="container">
            Page Not Found
        </div>
    </section>
</template>

<script>
    export default {
        name: 'PageNotFound'
    };
</script>