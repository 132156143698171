<template>
    <div>
        <div class="columns" style="background-color: #1E0635;">
            <div class="column">
                <strong class="title is-5"><a class="has-text-white" @click="goHome">Home</a></strong>
            </div>

            <div class="column">
                <strong class="title is-5"><a class="has-text-white" @click="goToClinics">Clinics</a></strong>
            </div>

            <div class="column" v-if="isLoggedIn">
                <strong @click="logout" class="title is-5"><a class="has-text-white">Log Out</a></strong>
            </div>
        </div>
        <div>
            <div class="container">
                <div class="card">
                    <div class="card-content">
                        <h1 class="title is-4">
                            <p>{{ clinic }}</p>
                        </h1>
                        <div class="notification">
                            <p>Rank applicants by dragging them from the ‘Unranked Applicants’ pool to the ‘Ranking’
                                table. Applicants may be rearranged through dragging. If a ranked applicant is
                                dragged to another filled spot, it will swap the two applicants. If you swap
                                an unranked applicant into a filled ranking slot, the other applicant will become
                                unranked.
                            </p>
                            <br />
                            <p>To save and submit, press ‘Preview’ to confirm the current ranking list and then click
                                'Save to CAMS'.</p>
                            <br />
                            <p>Please rank ALL applicants, placing ineligible applicants at the bottom. If you must
                                exclude
                                an applicant, please email <a href="mailto:susan.hodges@nyu.edu">Susan Hodges</a> with
                                their name and reason for leaving them unranked.</p>
                        </div>
                        <div class="columns">
                            <div class="column is-two-thirds" id="rankingColumn">
                                <h1 class="title is-4">Applicant Rankings</h1>
                                <b-table :id="'rankingTable'" v-if="showRankingTable" :data="rankingData" bordered
                                    striped hoverable narrowed :paginated="rankingData.length >= 30"
                                    pagination-size="is-small" draggable @dragstart="dragstart" @drop="drop"
                                    @dragover="dragover" @dragleave="dragleave" @dragend="dragend"
                                    :is-row-selectable="(row) => !row.isLocked">
                                    <b-table-column field="rank" label="Rank" width="40" numeric v-slot="props">
                                        {{ props.row.rank }}
                                    </b-table-column>
                                    <b-table-column field="name" label="Name" v-slot="props">
                                        {{ props.row.name }}
                                    </b-table-column>
                                    <b-table-column field="class" label="Class" v-slot="props">
                                        {{ props.row.class }}
                                    </b-table-column>
                                    <b-table-column field="email" label="Email" v-slot="props">
                                        {{ props.row.email }}
                                    </b-table-column>
                                    <b-table-column label="" v-slot="props">
                                        <div class="buttons">
                                            <b-button v-if="props.row.applicationId != null
                    " :icon-right="props.row.isLocked == 'Y' ? 'lock' : 'lock-open'" size="is-small"
                                                :title="props.row.isLocked == 'Y' ? 'locked' : 'unlocked'"
                                                @click="toggleLockMethod(props.row)"></b-button>
                                        </div>
                                    </b-table-column>
                                    <b-table-column label="Actions" v-slot="props">
                                        <div class="buttons">
                                            <b-button v-if="props.row.rank != 1 && !props.row.isLocked &&
                    props.row.applicationId != null
                    " @click="shiftUp(props.row)" icon-right="arrow-up" size="is-small" title="Shift Up"></b-button>
                                            <b-button v-if="props.row.rank !=
                    rankingData.length && !props.row.isLocked &&
                    props.row.applicationId != null
                    " @click="shiftDown(props.row)" icon-right="arrow-down" size="is-small"
                                                title="Shift Down"></b-button>
                                            <b-button v-if="props.row.applicationId != null && !props.row.isLocked
                    " @click="unrank(props.row)" size="is-small" icon-right="x" title="Unrank Applicant">
                                            </b-button>
                                        </div>
                                    </b-table-column>
                                </b-table>
                                <br />
                                <div class="level">
                                    <div class="level-left">
                                        <div class="buttons">
                                            <button @click="resetRankings" class="button is-warning">
                                                Reset Rankings
                                            </button>
                                            <button class="button" @click="isPreviewModalActive = true">
                                                Preview Rankings
                                            </button>
                                        </div>
                                    </div>
                                    <div class="level-right">
                                    </div>
                                </div>
                            </div>
                            <div class="column is-one-third" id="unrankedColumn">
                                <h1 class="title is-4">Unranked Applicants</h1>

                                <b-table :data="unrankedData" :columns="unassignedColumns" bordered striped hoverable
                                    narrowed :paginated="unrankedData.length >= 30" pagination-size="is-small" draggable
                                    @dragstart="dragstart" @drop="drop" @dragover="dragover" @dragleave="dragleave"
                                    @dragend="dragend">
                                    <b-table-column field="name" label="Name" v-slot="props">
                                        {{ props.row.name }}
                                    </b-table-column>
                                    <b-table-column field="class" label="Class" v-slot="props">
                                        {{ props.row.class }}
                                    </b-table-column>
                                    <b-table-column field="email" label="Email" v-slot="props">
                                        {{ props.row.email }}
                                    </b-table-column>
                                    <template #empty>
                                        <div class="has-text-centered">
                                            No unranked applicants
                                        </div>
                                    </template>
                                </b-table>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal v-model="isPreviewModalActive" scroll="keep">
            <div class="modal-card">
                <header class="modal-card-head">
                    <button type="button" class="delete" @click="isPreviewModalActive = false" />
                </header>
                <section class="modal-card-body" id="finalizedTable">
                    <h1 class="title is-5">
                        <p>{{ clinic }}</p>
                    </h1>
                    <h1 class="subtitle is-6">
                        <p>Finalized Rankings</p>
                    </h1>
                    <b-table :data="rankingData" :columns="rankedColumns" bordered striped narrowed></b-table>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="submit">Save to CAMS</button>
                    <button class="button" @click="isPreviewModalActive = false">Cancel</button>
                </footer>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { dragAndDropMixin } from "../dragAndDropMixin";

export default {
    name: "HomeView",
    mixins: [dragAndDropMixin],
    data() {
        return {
            data: [],
            rankingData: [],
            unrankedData: [],
            rankedColumns: [
                {
                    field: "rank",
                    label: "Rank",
                    width: "40",
                    numeric: true,
                },
                {
                    field: "name",
                    label: "Name",
                },
                {
                    field: "class",
                    label: "Class",
                },
                {
                    field: "email",
                    label: "Email",
                },
            ],
            unassignedColumns: [
                {
                    field: "name",
                    label: "Name",
                },
                {
                    field: "class",
                    label: "Class",
                },
                {
                    field: "email",
                    label: "Email",
                },
            ],
            isPreviewModalActive: false,
            // the data being dragged,
            rowBeingDragged: null,
            // where we want to put the data
            dragDestination: null,
            showRankingTable: true,
        };
    },
    async mounted() {
        this.populateData();
        this.rerank();
    },
    computed: {
        ...mapGetters(["clinic", "clinicRanks", "isLoggedIn"]),
    },
    methods: {
        ...mapActions(["postRankings", "setClinic", 'goHome', 'goToClinics', 'logout', 'toggleLock']),

        async toggleLockMethod(application) {
            await this.toggleLock(application);
            this.resetRankings();
        },

        submit() {
            this.postRankings(this.data);
            this.isPreviewModalActive = false;
        },

        // Event: When you grab a b-table row, it is wrapped in a payload object. The payload's data is accessed via the row property.
        dragstart(payload) {
            if (payload.row.isLocked) return;
            // @TODO When Mozilla fixes the following bug: https://bugzilla.mozilla.org/show_bug.cgi?id=725156
            // remove the dataTransfer.setData workaround below. It looks like it's been open for 8 years - we'll see what happens
            payload.event.dataTransfer.setData(
                "application/x-workaround",
                payload.row
            );
            this.rowBeingDragged = payload.row;
        },

        dragover(target) {
            if (
                this.rowBeingDragged !== null && this.rowBeingDragged.applicationId != target.row.applicationId && !target.row.isLocked
            ) {
                this.$super().dragover(target);
                this.dragDestination = target.row;
            }
        },

        dragleave(target) {
            this.$super().dragleave(target);
            this.dragDestination = null;
        },

        copyDraggingRowDataToTarget(target) {
            // empty row
            if (target.row.applicationId == null) {
                const draggingId = this.rowBeingDragged.applicationId;
                const rowMatch = this.data.find(
                    (e) => e.applicationId == draggingId
                );
                rowMatch.rank = target.row.rank;
            }
            // moving an item from ranked table off into unranked
            else if (
                target.row.applicationId != null &&
                target.row.rank == null &&
                this.rowBeingDragged.applicationId != null
            ) {
                this.rowBeingDragged.rank = null;
            }
            // this is a swap
            else if (
                target.row.applicationId != null &&
                target.row.rank != null
            ) {
                const draggingRowRank = this.rowBeingDragged.rank;

                this.rowBeingDragged.rank = target.row.rank;
                target.row.rank = draggingRowRank;
            }

            this.rerank();

            // clear
            this.rowBeingDragged = null;
        },
        rerank() {
            this.showRankingTable = false;

            this.unrankedData = [];
            this.rankingData = [];
            let rowCount = 1;

            const onlyRanked = this.data.filter((e) => e.rank != null);

            while (rowCount <= this.data.length) {
                const rankedRow = onlyRanked.find((e) => e.rank == rowCount);
                if (rankedRow != null) {
                    this.rankingData.push(rankedRow);
                } else {
                    this.rankingData.push({
                        id: null,
                        rank: rowCount,
                        name: null,
                        class: null,
                        email: null,
                        date: null,
                        previous: null,
                    });
                }
                rowCount++;
            }

            this.rankingData = this.rankingData.sort((a, b) => a.rank - b.rank);

            this.data.forEach((element) => {
                if (element.rank == null) {
                    this.unrankedData.push(element);
                }
            });

            this.unrankedData = this.unrankedData.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }

                return 0;
            });

            this.showRankingTable = true;
        },
        populateData() {
            this.data = [];
            if (this.clinicRanks != null) {
                this.clinicRanks.forEach((e) => {
                    this.data.push(structuredClone(e));
                });
            }
        },
        resetRankings() {
            this.showRankingTable = false;
            this.populateData();
            this.rerank();
            this.showRankingTable = true;
        },
        async submitRankings() {
            await this.postRankings();
            this.populateData();
            this.rerank();
        },

        shiftUp(row) {
            if (row.rank == 1 || row.isLocked) {
                return;
            } else {
                // grab the preceding row
                let target = null;
                this.rankingData.forEach((e) => {
                    if (e.rank == row.rank - 1) target = e;
                });

                if (target != null && !target.isLocked) {
                    const currentRowRank = row.rank;

                    row.rank = target.rank;
                    target.rank = currentRowRank;
                }

                this.rerank();
            }
        },

        shiftDown(row) {
            if (row.rank == this.rankingData.length || row.isLocked) {
                return;
            } else {
                // grab the next row
                let target = null;
                this.rankingData.forEach((e) => {
                    if (e.rank == row.rank + 1) target = e;
                });

                if (target != null && !target.isLocked) {
                    const currentRowRank = row.rank;

                    row.rank = target.rank;
                    target.rank = currentRowRank;
                }

                this.rerank();
            }
        },

        unrank(row) {
            if (!row.isLocked) {
                row.rank = null;

                this.rerank();
            }
        },
    },
    filters: {
        lastNameFirst: function (value) {
            if (!value) return ''
            value = value.toString();
            const parts = value.split(' ');
            if (parts.length != 2) return value;
            return parts[1] + ', ' + parts[0];
        }
    }
};
</script>
